<script>
  /**
   * Kaminzimmer
   * © 2020, Hendrik Runte
   **/

  import { version } from './lib/version.js';
  import { log } from './lib/log.js';

  import Revision from './components/Revision.svelte';

  log(
    '%cThis is the Kaminzimmer client. It`s assembled with svelte.',
    'background-color: fuchsia; color: black; padding: 0 1em'
  );

  window.HELP_IMPROVE_VIDEOJS = false;
</script>

<!--
	Mind the 'global' attribute!
	This is needed to let 'prependData' work.
	See rollup.config.js
-->
<style global lang="scss">@charset "UTF-8";
/**
 * typography
 *
 */
:global(html) {
  font-family: system, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}

:global(body) {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 130%;
  color: #e6e6e6;
}

/**
 * keyframes
 */
@-webkit-keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@keyframes -global-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opactiy: 1;
  }
}
@-webkit-keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slipIn {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInLeft {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes -global-slideInRight {
  0% {
    opacity: 0.5;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * common
 *
 * All common style settings go here.
 * For font-family and other typographic stuff
 * see _typography.scss
 */
:global(html) {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: black;
  color: white;
}

:global(*),
:global(*:before),
:global(*:after) {
  box-sizing: inherit;
}

:global(html),
:global(body) {
  height: 100%;
}

:global(body) {
  margin: 0;
}

:global(.fadein) {
  -webkit-animation: 0.5s ease-out 0s 1 fadeIn;
          animation: 0.5s ease-out 0s 1 fadeIn;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  margin: 0;
  font-weight: 600;
}

:global(h1) {
  display: flex;
  justify-content: space-between;
  color: white;
}
:global(h1) :global(span::before) {
  content: ":: ";
}

:global(h2) {
  color: #b3b3b3;
}

:global(h2::before) {
  content: ":: ";
}

:global(p) {
  margin: 0 0 1em 0;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

:global(q) {
  quotes: "»" "«" "›" "‹";
}
:global(q:lang(fr)) {
  quotes: "«" "»" "‹" "›";
}
:global(q:lang(en)) {
  quotes: "“" "”" "‘" "’";
}
:global(q:lang(ch)) {
  quotes: "» " " «" "› " " ‹";
}

:global(blockquote) {
  margin: 0;
  padding: 0;
}

:global(a:-webkit-any-link) {
  text-decoration: none;
}

:global(a:-moz-any-link) {
  text-decoration: none;
}

:global(a:any-link) {
  text-decoration: none;
}

:global(ul),
:global(li),
:global(dl),
:global(dt),
:global(dd) {
  padding: 0;
  margin: 0;
  list-style: none;
}

:global(:focus) {
  outline: none;
}

:global(main) {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  gap: 1ch;
  background-image: url(./images/fireplace.jpg);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

:global(#kaminzimmer-player) {
  width: 70vw;
  height: 43vw;
}</style>

<header />
<main>
  <video
    id="kaminzimmer-player"
    class="video-js"
    controls
    preload="auto"
    poster="/images/fireplace-sharp.jpg"
    data-setup="">
    <source
      src="https://stream.granus.net/hls/wald.m3u8"
      type="application/x-mpegURL"
      res="9999"
      label="auto" />
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">
        supports HTML5 video
      </a>
    </p>
  </video>
</main>
<Revision {version} />
